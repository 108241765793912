import type { TrpcRouterOutput } from '@calories/admin-backend/src/router'
import { zGetUserAnalyticTrpcInput } from '@calories/admin-backend/src/router/users/getUserAnalytic/input'
import { zUpdateAppliedAddCoinsEventsTrpcInput } from '@calories/admin-backend/src/router/users/updateAppliedAddCoinsEvents/input'
import { zUpdateUserCoinsTrpcInput } from '@calories/admin-backend/src/router/users/updateUserCoins/input'
import { zUpdateUserParamsAndDietTrpcInput } from '@calories/admin-backend/src/router/users/updateUserParamsAndDiet/input'
import { canViewUsers } from '@calories/admin-backend/src/utils/can'
import { addDays } from 'date-fns'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { RadioButtons } from '../../../components/RadioButtons'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
// import { env } from '../../../lib/env'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUserImagesRoute, getUserRoute, getUserTopicsRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const Destroy = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const destroyUser = trpc.destroyUser.useMutation()
  // const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      destroy: '' as any,
    },
    validationSchema: z.object({
      destroy: z.string(),
    }),
    onSubmit: async (values) => {
      if (values.destroy.toLowerCase().trim() !== user.serialNumber.toString()) {
        throw new Error('Confirmation is not correct')
      }
      await destroyUser.mutateAsync({
        userId: user.id,
      })
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'User destroyed',
  })
  return (
    <Segment size={2} title="Destroy">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Confirmation" hint="Type user serial number to confirm" name="destroy" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps} disabled={buttonProps.disabled}>
            Destroy
          </Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const Subscription = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const updateUserSubscription = trpc.updateUserSubscription.useMutation()
  // const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      expiresAfterDays: '' as any,
    },
    validationSchema: z.object({
      expiresAfterDays: z.string().regex(/^\d+$/),
    }),
    onSubmit: async (values) => {
      await updateUserSubscription.mutateAsync({
        serialNumber: user.serialNumber,
        expiresAt: addDays(new Date(), +values.expiresAfterDays),
      })
      // trpcUtils.getAppConfig.setData(undefined, result)
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Subscription expiration date updated. Will be applied in next 2 minutes.',
  })
  return (
    <Segment size={2} title="Subscription">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Expires After Days Count" name="expiresAfterDays" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const Coins = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const updateUserCoins = trpc.updateUserCoins.useMutation()
  const trpcUtils = trpc.useUtils()
  const { formik, alertProps, buttonProps } = useForm({
    validationSchema: zUpdateUserCoinsTrpcInput.omit({
      serialNumber: true,
    }),
    initialValues: {
      freeCoinsCountPerMonth: user.freeCoinsCountPerMonth.toString() as any,
      coinsCount: user.coinsCount.toString() as any,
    },
    onSubmit: async (values) => {
      const result = await updateUserCoins.mutateAsync({
        serialNumber: user.serialNumber,
        ...values,
      })
      trpcUtils.getUser.setData(
        {
          serialNumber: user.serialNumber,
        },
        (prevData) =>
          prevData && {
            ...prevData,
            user: {
              ...prevData.user,
              ...result.user,
            },
          }
      )
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Coins updated',
  })
  return (
    <Segment size={2} title="Coins">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Free Coins Count Per Month" name="freeCoinsCountPerMonth" formik={formik} />
          <Input label="Current Coins Count" name="coinsCount" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const AppliedAddCoinsEvents = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const updateAppliedAddCoinsEvents = trpc.updateAppliedAddCoinsEvents.useMutation()
  const trpcUtils = trpc.useUtils()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      appliedAddCoinsEvents: user.appliedAddCoinsEvents.join('\n'),
    },
    validationSchema: z.object({
      appliedAddCoinsEvents: z.string(),
    }),
    onSubmit: async (values) => {
      const appliedAddCoinsEvents = values.appliedAddCoinsEvents
        .split('\n')
        .map((x) => x.trim())
        .filter((x) => x)

      const input = zUpdateAppliedAddCoinsEventsTrpcInput.parse({
        appliedAddCoinsEvents,
        serialNumber: user.serialNumber,
      })
      const result = await updateAppliedAddCoinsEvents.mutateAsync(input)
      trpcUtils.getUser.setData(
        {
          serialNumber: user.serialNumber,
        },
        (prevData) =>
          prevData && {
            ...prevData,
            user: {
              ...prevData.user,
              ...result.user,
            },
          }
      )
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Applied add coins events updated',
  })

  return (
    <Segment title="Applied Coins Events" size={2}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Textarea label="Event Names" name="appliedAddCoinsEvents" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

const ananlyticValidationSchema = zGetUserAnalyticTrpcInput
const Ananlytic = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const { formik } = useForm({
    initialValues: { dateFrom: '', dateTo: '', userId: user.id },
    validationSchema: ananlyticValidationSchema,
  })
  const filter: any = (() => {
    const maybeFilter = {
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
      userId: user.id,
    }
    const parseResult = ananlyticValidationSchema.safeParse(maybeFilter)
    return parseResult.success
      ? maybeFilter
      : {
          dateFrom: '',
          dateTo: '',
          userId: user.id,
        }
  })()
  const queryResult = trpc.getUserAnalytic.useQuery(filter)
  return (
    <Segment title="Ananlytic" size={2}>
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={formik} />
        </FormItems>
      </div>
      {queryResult.error ? (
        <Alert color="red">{queryResult.error.message}</Alert>
      ) : !queryResult.data ? (
        <Loader type="section" />
      ) : (
        <div className={css.result}>
          <pre className={css.data}>{JSON.stringify(queryResult.data, null, 2)}</pre>
        </div>
      )}
    </Segment>
  )
}

const inchesToFootesString = (inches: number) => {
  const footes = Math.floor(inches / 12)
  const inFt = inches % 12
  return `${footes}'${inFt}"`
}
const footesStringToInches = (footesString: string) => {
  const [footesPartString, inchesPartString] = footesString.split("'")
  const footes = footesPartString.replace(/\D/g, '')
  const inches = inchesPartString.replace(/\D/g, '')
  return +footes * 12 + +inches
}

const Diet = ({ user }: { user: TrpcRouterOutput['getUser']['user'] }) => {
  const updateUserParamsAndDiet = trpc.updateUserParamsAndDiet.useMutation()
  const trpcUtils = trpc.useUtils()
  const { formik, alertProps, buttonProps } = useForm({
    validationSchema: zUpdateUserParamsAndDietTrpcInput
      .omit({
        height: true,
      })
      .extend({
        height: z.string(),
      })
      .superRefine((data, ctx) => {
        if (data.heightUnit === 'cm' && !data.height.match(/^\d+$/)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Height in cm must be a number',
            path: ['height'],
          })
        }
        if (data.heightUnit === 'in' && !data.height.match(/^\d+'\d+"$/)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Height in ft must be in format like 5\'9"',
            path: ['height'],
          })
        }
      }),
    initialValues: {
      userId: user.id,
      weight: user.weight,
      activityLevel: user.activityLevel,
      height: user.heightUnit === 'cm' ? user.height.toString() : inchesToFootesString(user.height),
      heightUnit: user.heightUnit,
      targetWeight: user.targetWeight,
      targetDurationWeeks: user.targetDurationWeeks,
      weightUnit: user.weightUnit,
      birthDate: user.birthDate,
      gender: user.gender,
    },
    onSubmit: async (values) => {
      const height = values.heightUnit === 'cm' ? +values.height : footesStringToInches(values.height)
      const result = await updateUserParamsAndDiet.mutateAsync({
        ...values,
        height,
      })
      trpcUtils.getUser.setData(
        {
          serialNumber: user.serialNumber,
        },
        (prevData) =>
          prevData && {
            ...prevData,
            user: {
              ...prevData.user,
              ...result.user,
            },
          }
      )
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Params & diet updated',
  })
  return (
    <Segment size={2} title="Params & Diet">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <RadioButtons
            label="Gender"
            name="gender"
            formik={formik}
            options={[
              {
                label: 'male',
                value: 'male',
              },
              {
                label: 'female',
                value: 'female',
              },
              {
                label: 'other',
                value: 'other',
              },
            ]}
          />
          <Input label="Birth Date" name="birthDate" formik={formik} type="date" />
          <Input label="Weight" name="weight" formik={formik} />
          <RadioButtons
            label="Weight Unit"
            name="weightUnit"
            formik={formik}
            options={[
              {
                label: 'kg',
                value: 'kg',
              },
              {
                label: 'lbs',
                value: 'lbs',
              },
            ]}
          />
          <Input label="Height" name="height" formik={formik} />
          <RadioButtons
            label="Height Unit"
            name="heightUnit"
            formik={formik}
            options={[
              {
                label: 'cm',
                value: 'cm',
              },
              {
                label: 'in',
                value: 'in',
              },
            ]}
          />
          <RadioButtons
            label="Activity Level"
            name="activityLevel"
            formik={formik}
            options={[
              {
                label: 'Lightly active',
                value: 'Lightly active',
              },
              {
                label: 'Moderately active',
                value: 'Moderately active',
              },
              {
                label: 'Very active',
                value: 'Very active',
              },
            ]}
          />
          <Input label="Target Weight" name="targetWeight" formik={formik} />
          <Input label="Target Duration Weeks" name="targetDurationWeeks" formik={formik} />

          <Alert {...alertProps} />
          <Button {...buttonProps}>Update Diet</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

export const UserPage = withPageWrapper({
  useQuery: () => {
    const { userSerialNumber } = getUserRoute.useParams()
    return trpc.getUser.useQuery({
      serialNumber: +userSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    user: queryResult.data.user,
  }),
  checkAccess: ({ ctx }) => canViewUsers(ctx.me),
  showLoaderOnFetching: false,
  title: ({ user }) => `User ${user.serialNumber}`,
})(({ user }) => (
  <Segment title={`User ${user.serialNumber}`}>
    <Segment>
      <div className={css.extra}>
        <a href={`https://app.adapty.io/profiles/users?filter%5Bsearch%5D=${user.id}`} target="_blank" rel="noreferrer">
          Adapty Profile
        </a>
        <br />
        <Link
          to={getUserImagesRoute({
            userSerialNumber: user.serialNumber.toString(),
          })}
        >
          Generated Images
        </Link>
        <br />
        <Link
          to={getUserTopicsRoute({
            userSerialNumber: user.serialNumber.toString(),
          })}
        >
          Topics List
        </Link>
      </div>
      <pre className={css.data}>{JSON.stringify(user, null, 2)}</pre>
    </Segment>
    <Diet user={user} />
    <Ananlytic user={user} />
    <Subscription user={user} />
    <Coins user={user} />
    <AppliedAddCoinsEvents user={user} />
    {/* {env.HOST_ENV !== 'production' && <Destroy user={user} />} */}
    <Destroy user={user} />
  </Segment>
))

import { zStringDateFromOptonal, zStringDateToOptonal, zStringOptional } from '@calories/shared/src/zod'
import { z } from 'zod'

export const zGetConversionsStatsTrpcInput = z.object({
  search: zStringOptional,
  groupBy: zStringOptional,
  installDateFrom: zStringDateFromOptonal,
  installDateTo: zStringDateToOptonal,
  purchaseDateFrom: zStringDateFromOptonal,
  purchaseDateTo: zStringDateToOptonal,
})

import { z } from 'zod'

// weight: number
// targetWeight: number
// weightUnit: 'kg' | 'lbs'
// height: number
// heightUnit: 'cm' | 'in'
// activityLevel: 'low' | 'medium' | 'high'
// gender: 'male' | 'female' | 'non-binary'
// age: number
// targetDurationWeeks: number

export const zUpdateUserParamsAndDietTrpcInput = z.object({
  userId: z.string().min(1),
  weight: z.coerce.number(),
  targetWeight: z.coerce.number(),
  targetDurationWeeks: z.coerce.number(),
  weightUnit: z.union([z.literal('kg'), z.literal('lbs')]),
  height: z.coerce.number(),
  heightUnit: z.union([z.literal('cm'), z.literal('in')]),
  activityLevel: z.string(),
  birthDate: z.coerce.date(),
  gender: z.union([z.literal('male'), z.literal('female'), z.literal('other')]),
})
